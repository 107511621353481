<template>
  <section class="cultureWaterGold">
    <WaterGoldItem v-for="item in itemList" :key="item.title" :item="item" />
  </section>
</template>

<script>
import WaterGoldItem from '@/components/Culture/WaterGold/WaterGoldItem.vue'
import ShueiJinJiouData from '@/assets/data/ShueiJinJiou'

export default {
  components: {
    WaterGoldItem
  },
  data() {
    return {
      itemList: ShueiJinJiouData
    }
  }
}
</script>

<style lang="scss">
.cultureWaterGold {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 13.2rem;

  & * + * h3 {
    border-left: 1px dashed #d0a805;
  }
}

@media (max-width: 768px) {
  .cultureWaterGold {
    padding-bottom: 0;
  }
}
</style>
